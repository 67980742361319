import cloneDeep from 'lodash/cloneDeep'

export default {
  data () {
    return {
      initFormData: {
        name: '',
        orgTypeId: null,
        enabled: true,
        remark: ''
      },
      toolbarButtonList: [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          const firstOrg = this.orgTypeRelation[0]
          this.formData.orgTypeId = firstOrg.value
          this.formData.orgTypeName = firstOrg.label
          this.formSubType = 'add'
          this.drawer = true
        }
      }],
      formButtonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: (form) => {
          switch (this.formSubType) {
            case 'add':
              return this.handleAdd(form)
            case 'edit':
              return this.handleEdit(form)
            default:
              break
          }
        }
      }, {
        label: '重置',
        func: () => {
          this.resetForm()
        }
      }],
      operateButtonList: [{
        label: '新增',
        name: 'addChild',
        type: 'text',
        textType: 'primary',
        showabled: (data) => data.orgTypeId !== this.lastOrgType.value,
        func: ({ data }) => {
          const curOrgLevel = this.orgTypeRelation.findIndex(o => o.value === data.orgTypeId)
          const nextOrg = this.orgTypeRelation[curOrgLevel + 1]
          const initData = {
            name: '',
            orgTypeId: nextOrg.value,
            orgTypeName: nextOrg.label,
            enabled: true,
            remark: '',
            parentId: data.id
          }
          this.formData = initData
          this.initFormData = cloneDeep(initData)
          this.drawer = true
          this.formSubType = 'add'
        }
      }, {
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        func: ({ data }) => {
          this.formData = cloneDeep(data)
          this.initFormData = cloneDeep(data)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        func: this.handleDelete
      }]
    }
  },
  methods: {
    handleAdd (form) {
      return new Promise((resolve, reject) => {
        const { formData } = form
        this.$post({
          url: '/wfbase/rbac/org',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.handleClose()
            this.resetForm()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleEdit (form) {
      return new Promise((resolve, reject) => {
        const { id, orgTypeId, orgTypeName, name, remark, enabled, parentId, code } = form.formData
        this.$put({
          url: `/wfbase/rbac/org/${id}`,
          data: {
            id, orgTypeId, orgTypeName, name, remark, enabled, parentId, code
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$httpDelete({
          url: `/wfbase/rbac/org/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('删除成功!')
            this.getData()
            resolve('success')
          })
          .catch(reject)
      })
    },
    resetForm () {
      this.formData = cloneDeep(this.initFormData)
      this.$nextTick(() => {
        const formRef = this.$refs.form
        formRef && formRef.$refs.dataform.clearValidate()
      })
    }
  }
}
