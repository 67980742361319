export const columns = [
  { name: 'name', label: '组织名称', align: 'left', headerAlign: 'center' },
  { name: 'orgTypeName', label: '组织类型' },
  { name: 'code', label: '组织代码' },
  { name: 'enabled', label: '是否可见', render: { type: 'yesOrNo' } },
  { name: 'remark', label: '备注' }
]

export const filterFields = [{
  name: 'name',
  label: '组织名称',
  form: {
    tag: 'input',
    filterType: 'like'
  }
}, {
  name: 'orgTypeId',
  label: '组织类型',
  form: {
    tag: 'select',
    relation: 'orgType'
  }
}]

export const formFields = [
  {
    name: 'name',
    label: '组织名称',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'orgTypeId',
    label: '组织类型',
    render: { type: 'select' },
    relation: 'orgType',
    form: {
      tag: 'text'
    }
  },
  {
    name: 'code',
    label: '组织代码',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'enabled',
    label: '是否可见',
    render: { type: 'select' },
    form: {
      tag: 'switch',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'remark',
    label: '备注',
    form: {
      tag: 'input',
      inputType: 'textarea'
    }
  }
]
