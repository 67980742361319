export const columns = [
  { name: 'name', label: '菜单名称', align: 'left', headerAlign: 'center' },
  {
    name: 'menuTypeName',
    label: '菜单类型'
  },
  { name: 'url', label: '菜单路径' },
  { name: 'icon', label: '菜单图标' },
  { name: 'sort', label: '排序' },
  {
    name: 'enabled',
    label: '是否可见',
    width: 100,
    options: [{
      label: '是',
      value: true
    }, {
      label: '否',
      value: false
    }],
    render: { type: 'select' }
  },
  { name: 'remark', label: '备注' }
]

export const filterFields = [{
  name: 'name',
  label: '菜单名称',
  form: {
    tag: 'input',
    filterType: 'like'
  }
}]

export const formFields = [
  {
    name: 'name',
    label: '菜单名称',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'menuTypeId',
    label: '菜单类型',
    form: {
      tag: 'select',
      rules: [{ required: true, message: '必填' }],
      options: [
        { label: '终端', value: 'TERMINAL' },
        { label: '系统', value: 'SYSTEM' },
        { label: '模块', value: 'MODULE' },
        { label: '页面', value: 'PAGE' },
        { label: '按钮', value: 'BUTTON' }
        // { label: '标签页', value: 'TAB' }
        // { label: '首页', value: 'HOME' },
        // { label: '子页面', value: 'SUBPAGE' },
        // { label: '外部链接', value: 'OUTLINK' }
      ],
      chains: (form) => {
        const options = form.chains.getCurrentOptions()
        if (!options) return
        const { label } = options.find(item => item.value === form.value) || {}
        form.chains.setValue('menuTypeName', label)
      }
    }
  },
  {
    name: 'url',
    label: '菜单路径',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'icon',
    label: '菜单图标',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'sort',
    label: '排序',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'enabled',
    label: '是否可见',
    render: { type: 'select' },
    form: {
      tag: 'select',
      rules: [{ required: true, message: '必填' }],
      options: [{
        label: '是',
        value: true
      }, {
        label: '否',
        value: false
      }]
    }
  },
  {
    name: 'remark',
    label: '备注',
    form: {
      tag: 'input',
      inputType: 'textarea'
    }
  }
]
