<template lang="pug">
.wf-table.full-box
  DataTable(
    ref="menuTable"
    isTree
    :hasPage="false"
    resource="/wfbase/rbac/menu"
    :loading="loading"
    :size="tableSize"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList"
    :defaultSortBy="[{prop: 'sort', order: 'asc'}]")
  el-drawer(
    :title="formTitle"
    size="50%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
    DataForm.drawerForm(
      v-model="formData"
      ref="form"
      type="row"
      buttonAlign="center"
      :formFields="formFields"
      :buttonList="formButtonList")
</template>

<script>
import { columns, filterFields, formFields } from './config.js'
import buttonList from './buttonList'

export default {
  name: 'MenuManage',
  mixins: [buttonList],
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '菜单管理'
      }
    }
  },
  data () {
    return {
      // 侧拉表单是否展开
      drawer: false,
      // 表格是否loading
      loading: false,
      // 表格尺寸
      tableSize: 'medium',
      // 表格筛选表单配置
      filterFields,
      // 表格列配置
      columns,
      // 表格数据
      tableData: [],
      // 表单配置
      formFields,
      // 表单数据
      formData: {},
      // 表单提交类型
      formSubType: '' // add/edit
    }
  },
  methods: {
    getData () {
      this.$refs.menuTable.reRenderTableData()
    },
    handleDrawerOpen () {
      const formRef = this.$refs.form
      formRef && formRef.$refs.dataform.clearValidate()
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
      this.initFormData = {}
      this.$set(this, 'formData', this.initFormData)
      this.resetForm()
    }
  },
  mounted () {
    // this.getData()
  }
}
</script>
<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.drawerForm
  padding: 8px
</style>
