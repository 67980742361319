<template lang='pug'>
el-form.select-main(
  v-if="show"
  v-bind="$attrs"
  v-maxHeight="inline && isCollapse"
  key="formkey"
  ref="form"
  :model="tableData"
  @validate="recodeValidate")
  table.table-main.text-center(
    border="1"
    bordercolor="#ccc"
    align="center"
    cellpadding="0"
    cellspacing="0"
    style="border-collapse:collapse;")
    thead
      tr.thead-style
        th.text-center(
          v-for="(thead, i) in titleLists"
          :key="`thead_${i}`"
        ) {{thead}}
    tbody
      tr(
        v-for="(row, i) in tableData"
        :key="`tr_${i}`")
        td(
          v-for="(field, colK) in formFields"
          :key="`col_${colK}`")
          DataformItem(
            v-model="row[field.name]"
            v-bind="field"
            :field="field"
            :full="row"
            :key="field.name"
            :formData="tableData"
            :formFields="formFields"
            type="row"
            :failFields="validateFailFields")
          //- span.text-danger.fs-12.p-r-5(v-if="validateGroups[tCol]&&validateGroups[tCol].includes(i)") 必填
        //- td.icon-main
        //-   i.el-icon-remove-outline.cursor(@click="handleRemove(i)")
        //-   i.el-icon-circle-plus-outline.cursor(@click="handleAdd")
</template>

<script>
/**
 * @name 用户管理-身份信息选择
 */
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import DataformItem from '@/components/Dataform/DataformItem'

export default {
  name: 'UserIdentity',
  components: {
    DataformItem
  },
  props: {
    value: {
      type: Array,
      default: () => ([])
    }
  },
  data () {
    return {
      show: false,
      formFields: [{
        name: 'orgId',
        label: '',
        form: {
          tag: 'cascader',
          relation: 'orgs',
          valueKey: 'value',
          propsValue: 'value',
          parentKey: 'parentId',
          placeholder: '请选择部门',
          props: { checkStrictly: true },
          rules: [{ required: true, message: '必填' }]
        }
      },
      {
        name: 'roles',
        label: '',
        form: {
          tag: 'select',
          relation: 'roles',
          multiple: true
        }
      }],
      titleLists: ['部门', '角色'],
      validateFailFields: {}
    }
  },
  computed: {
    tableData: {
      get () {
        return this.value
      },
      set (value) {
        this.$emit('input', value)
      }
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    handleRemove (index) {
      this.tableData.splice(index, 1)
      if (!this.tableData.length) this.handleAdd()
    },
    handleAdd () {
      this.tableData.push({
        orgId: '',
        dutyTypeId: 'STAFF',
        roles: ''
      })
    },
    renderRelation () {
      this.getRelations(['orgs', 'roles'])
        .then(res => {
          renderRelationColumns(res, [this.formFields])
          this.$nextTick(() => { this.show = true })
        })
    },
    recodeValidate (fieldName, flag, message) {
      if (!flag) {
        this.$set(this.validateFailFields, fieldName, message)
      } else if (flag && Object.hasOwnProperty.call(this.validateFailFields, fieldName)) {
        this.$set(this.validateFailFields, fieldName, null)
        delete this.validateFailFields[fieldName]
      }
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>

<style lang='sass' scoped>
$bgc-title: #f5f5f7
.table-main
  width: 100%
  th
    font-size: 14px
    background-color: $bgc-title
  th, td
    padding: 8px
  td
    width: 45%
  td.icon-main
    width: 10%
    text-align: center
    i + i
      margin-left: 8px
</style>
<style scoped>
.select-main >>> .el-form-item {
  margin-bottom: unset
}
.select-main >>> .form-item_component {
  width: 100%
}
</style>
