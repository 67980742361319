import cloneDeep from 'lodash/cloneDeep'

export default {
  data () {
    return {
      initFormData: {},
      toolbarButtonList: [
        {
          label: '新增',
          name: 'add',
          type: 'primary',
          func: () => {
            this.drawer = true
            this.formSubType = 'add'
          }
        }],
      formButtonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: (form) => {
          switch (this.formSubType) {
            case 'add':
              return this.addMenu(form)
            case 'edit':
              return this.editMenu(form)
            default:
              break
          }
        }
      }, {
        label: '重置',
        func: () => {
          this.resetForm()
        }
      }],
      operateButtonList: [{
        label: '新增',
        name: 'addChild',
        type: 'text',
        textType: 'primary',
        showabled: (data) => data.menuTypeId !== 'BUTTON',
        func: ({ data }) => {
          const initData = {
            systemId: data.systemId,
            menuTypeId: 'PAGE',
            parentId: data.id,
            url: '',
            enabled: true,
            menuTypeName: '页面'
          }
          this.formData = initData
          this.initFormData = cloneDeep(initData)
          this.drawer = true
          this.formSubType = 'add'
        }
      }, {
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        func: ({ data }) => {
          this.formData = cloneDeep(data)
          this.initFormData = cloneDeep(data)
          this.drawer = true
          this.formSubType = 'edit'
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        func: this.deleteMenu
      }]
    }
  },
  methods: {
    addMenu (form) {
      return new Promise((resolve, reject) => {
        const { formData } = form
        this.$post({
          url: '/wfbase/rbac/menu',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.handleClose()
            this.resetForm()
            this.getData()
          })
          .catch(reject)
      })
    },
    editMenu (form) {
      return new Promise((resolve, reject) => {
        const { id, label, menuTypeId, menuTypeName, name, remark, icon, sort, url, enabled, parentId } = form.formData
        this.$put({
          url: `/wfbase/rbac/menu/${id}`,
          data: {
            id, label, menuTypeId, menuTypeName, name, remark, icon, sort, url, enabled, parentId
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    deleteMenu ({ data }) {
      return new Promise((resolve, reject) => {
        this.$httpDelete({
          url: `/wfbase/rbac/menu/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('删除成功!')
            this.getData()
          })
          .catch(reject)
      })
    },
    resetForm () {
      this.formData = cloneDeep(this.initFormData)
    }
  }
}
