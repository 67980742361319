<template lang="pug">
.wf-table.full-box
  DataTable(
    v-if="showTable"
    ref="orgTable"
    serverSide
    resource="/wfbase/rbac/role"
    :loading="loading"
    size="medium"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList")
  el-drawer(
    :title="formTitle"
    size="50%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
    DataForm.drawerForm(
      v-if="drawer"
      v-model="formData"
      ref="form"
      type="row"
      buttonAlign="center"
      :formFields="formFields"
      :buttonList="formButtonList")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import { columns, filterFields, formFields } from './config.js'
import buttonList from './buttonList'
import cloneDeep from 'lodash/cloneDeep'

export default {
  name: 'RoleManage',
  mixins: [buttonList],
  computed: {
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '角色管理'
      }
    }
  },
  data () {
    return {
      showTable: false,
      // 侧拉表单是否展开
      drawer: false,
      // 表格是否loading
      loading: false,
      // 表格筛选表单配置
      filterFields,
      // 表格列配置
      columns,
      // 表格数据
      tableData: [],
      // 表单配置
      formFields,
      // 表单数据
      formData: {
        orgIds: []
      },
      // 表单提交类型
      formSubType: '' // add/edit
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['menus', 'waterWorks'])
        .then(res => {
          renderRelationColumns(res, [this.formFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getData () {
      this.$refs.orgTable.reRenderTableData()
    },
    handleDrawerOpen () {
      const formRef = this.$refs.form
      formRef && formRef.$refs.dataform.clearValidate()
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
      this.initFormData = {
        code: '',
        name: '',
        menuIds: [],
        enabled: true,
        remark: '',
        orgIds: []
      }
      this.$set(this, 'formData', cloneDeep(this.initFormData))
      this.resetForm()
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>
<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.drawerForm
  padding: 8px
</style>
