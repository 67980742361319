import cloneDeep from 'lodash/cloneDeep'

export default {
  data () {
    return {
      initFormData: {
        code: '',
        name: '',
        menuIds: [],
        enabled: true,
        remark: ''
      },
      toolbarButtonList: [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          // 新增时，code可填
          this.formFields[0].form = {
            tag: 'input',
            rules: [{ required: true, message: '必填' }]
          }
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          this.formSubType = 'add'
          this.drawer = true
        }
      }],
      formButtonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: (form) => {
          switch (this.formSubType) {
            case 'add':
              return this.handleAdd(form)
            case 'edit':
              return this.handleEdit(form)
            default:
              break
          }
        }
      }, {
        label: '重置',
        func: () => {
          this.resetForm()
        }
      }],
      operateButtonList: [{
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        func: ({ data }) => {
          // 新增时，code可填
          this.formFields[0].form = {
            tag: 'text'
          }
          const cData = cloneDeep(data)
          cData.orgIds = data.rolePermissions.map(i => i.orgId)
          this.formData = cData
          this.initFormData = cloneDeep(cData)
          this.formSubType = 'edit'
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        func: this.handleDelete
      }]
    }
  },
  methods: {
    handleAdd (form) {
      return new Promise((resolve, reject) => {
        const { formData } = form
        this.$post({
          url: '/wfbase/rbac/role',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.handleClose()
            this.resetForm()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleEdit (form) {
      return new Promise((resolve, reject) => {
        const { id, menuIds, name, remark, enabled, parentId, orgIds } = form.formData
        this.$put({
          url: `/wfbase/rbac/role/${id}`,
          data: {
            id, menuIds, name, remark, enabled, parentId, orgIds
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$httpDelete({
          url: `/wfbase/rbac/role/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            this.$message.success('删除成功!')
            this.getData()
          })
          .catch(reject)
      })
    },
    resetForm () {
      this.formData = cloneDeep(this.initFormData)
    }
  }
}
