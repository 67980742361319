export const columns = [
  { name: 'code', label: '角色代码' },
  { name: 'name', label: '角色名称' },
  { name: 'enabled', label: '是否可见', render: { type: 'yesOrNo' } },
  { name: 'remark', label: '备注' }
]

export const filterFields = [{
  name: 'name',
  label: '角色名称',
  form: {
    tag: 'input',
    filterType: 'like'
  }
}]

export const formFields = [
  {
    name: 'code',
    label: '角色代码',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'name',
    label: '角色名称',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  }, {
    name: 'orgIds',
    label: '水厂权限',
    form: {
      tag: 'select',
      filterable: true,
      multiple: true,
      // collapseTags: true,
      clearable: true,
      relation: 'waterWorks'
    }
  },
  {
    name: 'menuIds',
    label: '权限',
    form: {
      tag: 'tree',
      relation: 'menus',
      valueKey: 'id',
      propsValue: 'id'
    }
  },
  {
    name: 'enabled',
    label: '是否可见',
    render: { type: 'select' },
    form: {
      tag: 'switch',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'remark',
    label: '备注',
    form: {
      tag: 'input',
      inputType: 'textarea'
    }
  }
]
