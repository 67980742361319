<template lang="pug">
.wf-table.full-box
  DataTable(
    ref="table"
    resource="/quarzs/queryAllJob"
    :hasPage="false"
    :filterFields="[]"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operateButtonList="operateButtonList"
    :operationWidth="300")
  el-drawer(
    :title="`${formData.jobName}定时器管理`"
    size="50%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body)
    DataForm.drawerForm(
      v-if="drawer"
      v-model="formData"
      ref="form"
      type="row"
      :formFields="formFields"
      :buttonList="formButtonList")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep'
const required = [{ required: true, message: '必填' }]

export default {
  name: 'TimerManage',
  computed: {
    submitApiFuncName () {
      return this.submitApiFuncMap.get(this.subType)
    }
  },
  data () {
    return {
      drawer: false,
      columns: [
        { name: 'jobName', label: '定时器名称' },
        { name: 'jobGroupName', label: '分组名称' },
        { name: 'jobRule', label: 'Cron表达式' },
        { name: 'description', label: '定时器描述' },
        {
          name: 'nextFireTime',
          label: '下一次执行时间',
          render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm:ss' }
        },
        {
          name: 'priviousFireTime',
          label: '上一次执行时间',
          render: { type: 'date', timeType: 'YYYY-MM-DD HH:mm:ss' }
        },
        { name: 'jobStatus', label: '状态' }
      ],
      toolbarButtonList: [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          this.subType = 'add'
          this.formFields.forEach((field, index) => {
            this.formFields[index].form.tag = 'input'
            if (field.form.required) {
              this.formFields[index].form.rules = required
            }
          })
          this.formData = {
            className: '',
            jobName: '',
            jobGroupName: '',
            jobRule: '',
            description: ''
          }
          this.drawer = true
        }
      }],
      operateButtonList: [{
        label: '立即执行',
        name: 'action',
        type: 'text',
        textType: 'primary',
        disabled: (data) => data.jobStatus === 'PAUSED',
        func: ({ data }) => {
          return this.postApi({
            url: '/quarzs/runAJobNow',
            data,
            msg: '已执行'
          })
        }
      }, {
        label: '暂停',
        name: 'pause',
        type: 'text',
        textType: 'warning',
        disabled: (data) => data.jobStatus === 'PAUSED',
        func: ({ data }) => {
          return this.postApi({
            url: '/quarzs/pauseJob',
            data,
            msg: '已暂停'
          })
        }
      }, {
        label: '恢复',
        name: 'resume',
        type: 'text',
        textType: 'complete',
        disabled: (data) => data.jobStatus !== 'PAUSED',
        func: ({ data }) => {
          return this.postApi({
            url: '/quarzs/resumeJob',
            data,
            msg: '已恢复'
          })
        }
      }, {
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        func: ({ data }) => {
          this.subType = 'edit'
          this.formFields.forEach((field, index) => {
            const textFields = ['className', 'jobName', 'jobGroupName']
            this.formFields[index].form.tag = textFields.includes(field.name) ? 'text' : 'input'
            this.formFields[index].form.rules = textFields.includes(field.name) ? [] : required
          })
          this.formData = cloneDeep(data)
          this.drawer = true
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        func: this.handleDelete
      }],
      formData: {},
      subType: '',
      submitApiFuncMap: new Map([
        ['add', 'handleAdd'],
        ['edit', 'handleEdit']
      ]),
      formFields: [
        {
          name: 'className',
          label: '业务类名',
          form: {
            tag: 'input',
            required: true,
            rules: required
          }
        },
        {
          name: 'jobName',
          label: '定时器名称',
          form: {
            tag: 'input',
            required: true,
            rules: required
          }
        },
        {
          name: 'jobGroupName',
          label: '分组名称',
          form: {
            tag: 'input',
            required: true,
            rules: required
          }
        },
        {
          name: 'jobRule',
          label: 'Cron表达式',
          form: {
            tag: 'input',
            required: true,
            rules: required
          }
        },
        {
          name: 'description',
          label: '定时器描述',
          form: {
            tag: 'input',
            inputType: 'textarea'
          }
        }
      ],
      formButtonList: [{
        label: '提交',
        type: 'primary',
        validate: true,
        func: this.handleSubmit
      }]
    }
  },
  methods: {
    getData () {
      this.$refs.table.reRenderTableData()
    },
    postApi ({ url, data, msg }) {
      return new Promise((resolve, reject) => {
        this.$post({
          url,
          data
        }).then(res => {
          if (!res) {
            reject(res)
          } else {
            this.$message.success(msg)
            this.getData()
            resolve('success')
          }
        })
      })
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$httpDelete({
          url: '/quarzs/deleteJob',
          data
        }).then(res => {
          if (!res) {
            reject(res)
          } else {
            this.$message.success('已删除')
            this.getData()
            resolve('success')
          }
        })
      })
    },
    async handleSubmit (form) {
      try {
        if (!this.submitApiFuncName) return
        const result = await this[this.submitApiFuncName](form.formData)
        this.drawer = false
        this.getData()
        return result
      } catch (e) {
        return e
      }
    },
    handleAdd (formData) {
      return new Promise((resolve, reject) => {
        this.$post({
          url: '/quarzs/addJob',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
          })
          .catch(reject)
      })
    },
    handleEdit (formData) {
      return new Promise((resolve, reject) => {
        this.$patch({
          url: '/quarzs/updateJob',
          data: formData
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
          })
          .catch(reject)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.wf-table
  display: flex
  flex: 1
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
.drawerForm
  padding: 8px
</style>
