<template lang="pug">
.wf-table.full-box
  DataTable.main-table-box(
    v-if="showTable"
    ref="orgTable"
    keyName="orgTable"
    serverSide
    highlightClickRow
    resource="/wfbase/rbac/user"
    :loading="loading"
    size="medium"
    :extraFilterBy="extraFilterBy"
    :filterFields="filterFields"
    :columns="columns"
    :toolbarButtonList="toolbarButtonList"
    :operationWidth="200"
    :operateButtonList="operateButtonList"
    @current-change="handleCurrentChange")
  .second-table-box
    DataTable.info-table(
      v-if="showTable && infoResource"
      ref="infoTable"
      keyName="infoTable"
      serverSide
      :hasPage="false"
      :hasFooter="false"
      :resource="infoResource"
      :columns="infoColumns"
      :settingButtonList="[]")
      label.blue-header(slot="prep") 【{{userName || ''}}】身份信息
    .empty(v-else) 请先选择用户
  el-drawer.drawer-box(
    :title="formTitle"
    size="60%"
    :visible.sync="drawer"
    :modal="true"
    append-to-body
    :before-close="handleClose"
    @open="handleDrawerOpen")
    .drawerForm
      DataForm(
        v-model="formData"
        ref="form"
        type="row"
        buttonAlign="center"
        colspanNum="2"
        :formFields="formFields")
      label.blue-header 身份信息
      UserIdentity(v-model="formData.identifications")
      ButtonGroup.form-button(:buttonList="formButtonList")
</template>

<script>
import { mapActions } from 'vuex'
import { renderRelationColumns } from '@/utils/common.js'
import { columns, filterFields, formFields as formFieldsConfig } from './config.js'
import buttonList from './buttonList'
import cloneDeep from 'lodash/cloneDeep'
import UserIdentity from './components/UserIdentity.vue'

export default {
  name: 'AccountManage',
  mixins: [buttonList],
  components: { UserIdentity },
  computed: {
    infoResource () {
      return this.userId ? `/wfbase/rbac/identification/byUser/${this.userId}` : null
    },
    formTitle () {
      switch (this.formSubType) {
        case 'add':
          return '新增'
        case 'edit':
          return '修改'
        default:
          return '人员管理'
      }
    },
    formFields () {
      return formFieldsConfig.filter(item => this.formSubType === 'add' ? !!item : item.name !== 'loginPassword')
    }
  },
  data () {
    return {
      extraFilterBy: { userStatus: true },
      showTable: false,
      // 侧拉表单是否展开
      drawer: false,
      // 表格是否loading
      loading: false,
      // 表格筛选表单配置
      filterFields,
      // 表格列配置
      columns,
      // 表格数据
      tableData: [],
      // 表单数据
      formData: {},
      // 表单提交类型
      formSubType: '', // add/edit,
      userId: '',
      userName: '',
      infoColumns: [
        { name: 'orgId', label: '所属部门', relation: 'orgs', render: { type: 'path' } },
        { name: 'roles', label: '角色', relation: 'roles', render: { type: 'multiselect' } }
      ]
    }
  },
  methods: {
    ...mapActions('relation', ['getRelations']),
    renderRelation () {
      this.getRelations(['orgs', 'roles'])
        .then(res => {
          renderRelationColumns(res, [this.infoColumns, this.formFields])
          this.$nextTick(() => { this.showTable = true })
        })
    },
    getData () {
      this.$set(this, 'userId', '')
      this.$refs.orgTable.setCurrentRow()
      this.$refs.orgTable.reRenderTableData()
    },
    handleCurrentChange (row) {
      this.$set(this, 'userId', row ? row.id : '')
      this.userName = row ? row.name : ''
      this.$nextTick(() => {
        if (this.$refs.infoTable) this.$refs.infoTable.reRenderTableData()
      })
    },
    handleDrawerOpen () {
      const formRef = this.$refs.form
      formRef && formRef.$refs.dataform.clearValidate()
    },
    handleClose () {
      this.drawer = false
      this.formSubType = ''
      this.initFormData = {
        loginName: '',
        jobNumber: '',
        name: '',
        enabled: true,
        linkPhone: '',
        remark: '',
        loginPassword: '',
        identifications: [{
          orgId: '',
          roles: [],
          dutyTypeId: 'STAFF'
        }]
      }
      this.$set(this, 'formData', cloneDeep(this.initFormData))
      this.resetForm()
    }
  },
  created () {
    this.renderRelation()
  }
}
</script>
<style lang="sass" scoped>
.wf-table
  display: flex
  flex-direction: column
  background-color: #eee
  width: 100%
  height: 100%
  .main-table-box
    flex: 2
  .second-table-box
    flex: 1
    background-color: #fff
    margin-top: 8px
    padding-top: 8px
.drawer-box
  height: 100%
  .drawerForm
    position: relative
    padding: 8px
    width: 100%
    height: 100%
    overflow-y: auto
    overflow-x: hidden
    .form-button
      margin-top: 8px
</style>
