import cloneDeep from 'lodash/cloneDeep'

export default {
  data () {
    return {
      initFormData: {
        loginName: '',
        jobNumber: '',
        name: '',
        enabled: true,
        linkPhone: '',
        remark: '',
        loginPassword: '',
        identifications: [{
          orgId: '',
          roles: [],
          dutyTypeId: 'STAFF'
        }]
      },
      toolbarButtonList: [{
        label: '新增',
        name: 'add',
        type: 'primary',
        func: () => {
          this.$set(this.formFields[0].form, 'disabled', false)
          this.$set(this, 'formData', cloneDeep(this.initFormData))
          this.formSubType = 'add'
          this.drawer = true
        }
      }],
      formButtonList: [{
        name: 'submit',
        label: '提交',
        type: 'primary',
        validate: true,
        func: this.handleSubmit
      }, {
        label: '关闭',
        name: 'close',
        func: () => {
          this.drawer = false
        }
      }],
      operateButtonList: [{
        label: '修改',
        name: 'edit',
        type: 'text',
        textType: 'primary',
        func: ({ data }) => {
          return new Promise((resolve, reject) => {
            this.$get({
              url: `/wfbase/rbac/user/${data.id}`
            })
              .then(res => {
                if (!res) {
                  reject(res)
                  return
                }
                resolve('success')
                this.$set(this.formFields[0].form, 'disabled', true)
                data.identifications = res.data.identifications
                this.formData = cloneDeep(data)
                this.initFormData = cloneDeep(data)
                this.formSubType = 'edit'
                this.drawer = true
              })
              .catch(reject)
          })
        }
      }, {
        label: '删除',
        name: 'delete',
        type: 'text',
        textType: 'danger',
        func: this.handleDelete
      }, {
        label: '重置密码',
        name: 'reset',
        type: 'text',
        textType: 'primary',
        func: async ({ data }) => {
          try {
            await this.$confirm('是否重置密码', '提示')
            const res = await this.$put({ url: `/wfbase/rbac/user/resetPasswordDefault/${data.id}` })
            if (!res) return
            this.$message.success('重置密码成功')
            this.getData()
          } catch (e) {
            return e
          }
        }
      }]
    }
  },
  methods: {
    async handleSubmit (form) {
      try {
        await this.$refs.form.$refs.dataform.validate()
        let result = null
        switch (this.formSubType) {
          case 'add':
            result = await this.handleAdd(form)
            break
          case 'edit':
            result = await this.handleEdit(form)
            break
          default:
            break
        }
        return result
      } catch (e) {
        return e
      }
    },
    handleAdd () {
      return new Promise((resolve, reject) => {
        const { formData } = this
        this.$post({
          url: '/wfbase/rbac/user',
          data: {
            ...formData,
            loginPassword: this.$md5(formData.loginPassword)
          }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('新增成功!')
            this.handleClose()
            this.resetForm()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleEdit () {
      return new Promise((resolve, reject) => {
        const { id, jobNumber, linkPhone, loginName, enabled, name, identifications, remark } = this.formData
        this.$put({
          url: `/wfbase/rbac/user/${id}`,
          data: { id, jobNumber, linkPhone, loginName, enabled, name, identifications, remark }
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('修改成功!')
            this.handleClose()
            this.getData()
          })
          .catch(reject)
      })
    },
    handleDelete ({ data }) {
      return new Promise((resolve, reject) => {
        this.$httpDelete({
          url: `/wfbase/rbac/user/delete/${data.id}`
        })
          .then(res => {
            if (!res) {
              reject(res)
              return
            }
            resolve('success')
            this.$message.success('删除成功!')
            this.getData()
          })
          .catch(reject)
      })
    },
    resetForm () {
      this.formData = cloneDeep(this.initFormData)
    }
  }
}
