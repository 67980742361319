export const columns = [
  { name: 'loginName', label: '账号' },
  { name: 'jobNumber', label: '工号' },
  { name: 'name', label: '姓名' },
  { name: 'linkPhone', label: '联系手机' },
  { name: 'enabled', label: '是否可见', render: { type: 'yesOrNo' } },
  { name: 'remark', label: '备注' }
]

export const filterFields = [{
  name: 'loginName',
  label: '账号',
  form: {
    tag: 'input',
    filterType: 'like'
  }
}, {
  name: 'jobNumber',
  label: '工号',
  form: {
    tag: 'input',
    filterType: 'like'
  }
}, {
  name: 'name',
  label: '姓名',
  form: {
    tag: 'input',
    filterType: 'like'
  }
}, {
  name: 'userStatus',
  label: '状态',
  showable: false,
  form: {}
}]

export const formFields = [
  {
    name: 'loginName',
    label: '账号',
    form: {
      tag: 'input',
      disabled: false,
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'jobNumber',
    label: '工号',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'name',
    label: '姓名',
    form: {
      tag: 'input',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'loginPassword',
    label: '密码',
    form: {
      tag: 'input',
      inputType: 'password',
      rules: [
        { required: true, message: '必填' },
        {
          type: 'regexp',
          pattern: /^.*(?=.{6,})(?=.*\d)(?=.*[a-zA-Z]).*$/,
          message: '密码为字母加数字，最少六位'
        }
      ]
    }
  },
  {
    name: 'linkPhone',
    label: '联系手机',
    form: {
      tag: 'input'
    }
  },
  {
    name: 'enabled',
    label: '是否可见',
    render: { type: 'select' },
    form: {
      tag: 'switch',
      rules: [{ required: true, message: '必填' }]
    }
  },
  {
    name: 'remark',
    label: '备注',
    form: {
      tag: 'input',
      inputType: 'textarea',
      colSpan: 2
    }
  }
]
